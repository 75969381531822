













































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Recruit extends Vue {
  data() {
    return {
      logo: require("@/assets/power/img/logo-b.png"),
      form: {},
    };
  }
  public spop = false;
  show(): void {
    this.spop = true;
  }

  hide(): void {
    this.spop = false;
  }
}
